<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewCandidatesSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-candidates-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        اضافة طلب توظيف
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="الاسم"
              label-for="full-name"
            >
              <b-form-input
               
                v-model="userData.first_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
               
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="اسم الاب "
              label-for="father_name"
            >
              <b-form-input
               
                v-model="userData.father_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
               
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="اسم الام"
              label-for="mother_name"
            >
              <b-form-input
               
                v-model="userData.mother_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
               
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="الكنية"
            rules="required"
          >
            <b-form-group
              label="الكنية"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.last_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="الايميل"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Contact"
            rules="required"
          >
            <b-form-group
              label="رقم الهاتف"
              label-for="contact"
            >
              <b-form-input
                id="contact"
                v-model="userData.phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Company"
            rules="required"
          >
            <b-form-group
            label="العنوان"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
        
            <b-form-group
              label="المنصب الوظيفي"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.applied_position"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
           :options="positionOptions"
                :clearable="false"
                label="name"
                    :reduce="(val) => val.id"
           
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

    
          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="CV"
            rules="required"
          >
            <b-form-group
              label="CV"
              label-for="CV"
              :state="getValidationState(validationContext)"
            >
            <b-form-file
                id="cv"
                accept=".pdf"
                v-model="userData.cv"
                :state="getValidationState(validationContext)"
                
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              إضافة
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Vue from "vue";

import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCandidatesSidebarActive',
    event: 'update:is-add-new-candidates-sidebar-active',
  },
  props: {
    isAddNewCandidatesSidebarActive: {
      type: Boolean,
      required: true,
    },
    positionOptions: {
      type: Array,
      required: true,
    },
    
  },
  data() {
    return {
      required,
      alphaNum,
      email,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      father_name: '',
      mother_name: '',
      last_name: '',
      email: '',
      applied_position: null,
      cv: null,
     
      address: '',
      phone: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      let user = JSON.parse(localStorage.getItem("userData"));
   
      const formData = new FormData();
      formData.append("user_id", user.id);
      formData.append("last_name", userData.value.last_name);
      formData.append("mother_name", userData.value.mother_name);
      formData.append("father_name", userData.value.father_name);
      formData.append("phone", userData.value.phone);
      formData.append("address", userData.value.address);
      formData.append("applied_position", userData.value.applied_position);
      formData.append("email", userData.value.email);
      formData.append("cv", userData.value.cv);
      formData.append("first_name", userData.value.first_name);
      //console.log("formData",formData)
      store.dispatch('app-candidates/addCandidate',formData)
        .then(() => {
          Vue.swal({
              title: "تم  الاضافة بنجاح",
        icon: "success",
        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
          emit('refetch-data')
          emit('update:is-add-new-candidates-sidebar-active', false)
        })
    }
  

    const {
      refFormObserver,
      getValidationState,
      resetForm,

    } = formValidation(resetuserData)

    return {
    
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
