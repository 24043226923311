import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCandidates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/candidates', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPosition(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/position-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteCandidates(ctx, id) {
       
       
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/candidates/${id}` )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    Getcandidates(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/candidates/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    UpdateCandidate(ctx, payload) {
       
      
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/candidates/${payload.id}`,  payload.formData ,{ headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCandidate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/candidates',data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
